<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    content-class="b-dialog-question"
    :max-width="320"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal(false)"
  >
    <v-form @submit.prevent="reject" ref="form" lazy-validation v-model="valid">
      <v-card class="b-dialog-question-card">
        <div class="b-dialog-question-card--header">
          <v-icon color="error" size="40"> fa-regular fa-face-sad-cry </v-icon>
        </div>
        <div class="b-dialog-question-card--body">
          <h3 class="error--text">Deseja rejeitar a solução?</h3>
          <v-textarea
            id="message"
            name="message"
            label="Digite uma justificativa"
            color="error"
            outlined
            dense
            required
            :rules="[required]"
            v-model="model.message"
          />
          <v-file-input
            id="files"
            name="files"
            label="Adicionar arquivos"
            dense
            outlined
            multiple
            v-model="model.files"
          />
        </div>
        <div class="b-dialog-question-card--footer">
          <BaseButton
            id="btn-back"
            name="btn-back"
            outlined
            color="error"
            title="Cancelar"
            @click="closeModal(false)"
          />
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="error"
            title="Rejeitar"
            :disabled="!valid"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { SupportService } from '@/services/api/support';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    ticketId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    valid: true,
    model: {
      message: null,
      files: []
    }
  }),

  methods: {
    closeModal(redirect) {
      this.$emit('closeModal', redirect);
    },

    async reject() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const supportService = new SupportService();
        const { status } = await supportService.postRejectTicket({
          ...this.model,
          ticketId: this.ticketId
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
